import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable, tap } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getAccessToken()}`,
            },
        });
        return next.handle(request).pipe(tap({
            // force a user logout if the error code is 'unauthorized'
            // this is only thrown when the user's email isn't found in the database
            // should only happen if a user logs in and their user is deleted before their token expires
            error: (_error: HttpErrorResponse) => {
                if (_error.status === 401) {
                    this.auth.logout();
                }
            },
        }));
    }
}
